body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer
}

.rounded-end {
  border-radius: 0 .4rem .4rem 0;
}


.navbar-custom {
  background-color: #ececf1;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 240px;
  min-height: 70px;
}

.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.topbar .topbar-left {
  background: #1893d6;
  float: left;
  text-align: center;
  height: 70px;
  position: relative;
  width: 240px;
  z-index: 1;
}


.content-page {
  width: 100%;
  /* padding-top: 150px; */
  background: #f8f8fa;
}

.pagination {
  /* display: inline-block; */
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  padding-left: 10px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid lightgrey;
  cursor: pointer;
}



.pagination li.previous:not(.disabled) {
  background-color: #73B054;
  color: white
}

.pagination li.previous:not(.disabled) a {
  color: white
}

.pagination li.next:not(.disabled) {
  background-color: #73B054;
}

.pagination li.next:not(.disabled) a {
  color: white
}

.pagination li.active {
  background-color: #73B054;
  color: white
}

.pagination li.active a {
  color: white
}

.m-0 {
  margin: 0;
}

.select-card {
  position: relative;
  padding: 5px 10px 5px 10px;
  background-color: #ECECEC;
  border-radius: .3rem;
}

.select-error-card {
  border: 2px solid #B3272B;
  background-color: #FEC6C7;
}

.select-card-close {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #B3272B;
  outline: none;
  border: none;
  transition: all .2s ease;
}

.select-card-close:hover {
  scale: 1.1;
  transition: all .2s ease;
}


.operation-card {
  border-radius: 5px;
  margin-right: 5px;
  background-color: #73B054;
  color: #fff;
  text-align: center;
}

.operation-card p {
  margin: 5px 0 5px 0;
}

.operation-card .hour {
  background-color: #ECECEC;
  color: #333;
  justify-content: center;
  display: flex;
}

.operation-card .hour:not(:first-child) {
  border-top: 2px solid #fff;
}

.operation-card .hour:last-child {
  border-radius: 0 0 5px 5px;
}


.tab {
  min-height: 50px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #dcdcdc #dcdcdc white;
  text-align: center;
  font-size: 1.2rem;
  color: black;
  margin-right: 2px
}


.opcao_autocomplete {
  padding: 10px 10px 10px 10px;
  background-color: white;
  cursor: pointer;

}

.opcao_autocomplete:hover {
  background-color: #f2f2f2;
}

.col-form-label {
  margin-top: 10px;
  font-weight: bold;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.header-title {
  font-weight: bold;
}

.map2 {
  position: relative !important;
  height: 100%;
}

.hr_show {
  display: block;
}

.relacionados {
  display: block;
  padding: .5rem;
  cursor: pointer;
  z-index: 10000;
}

.hidden {
  display: none;
  bottom: 0;
  transform: translateX(20px);
  overflow: auto;
  opacity: 0;
}

.tables {
  opacity: 0;
  scale: 0.8;
  bottom: 0;
  overflow: auto;
}

.search {
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-button {
  border-radius: 50%;
  background-color: #73B054;
  color: #fff;
  border: none;
  height: 40px;
  width: 40px;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal {
  width: 100%;
  height: 100%;
  background-color: blue;
  position: absolute;
  display: none;
  box-shadow: 24px;
  z-index: 2000;
}

.filter {
  display: none;
  position: absolute;
  clear: both;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-36px);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1rem;
  border-radius: .4rem;
  z-index: 1;
}

.date {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: .4rem;
  font-weight: bold;
}

.active {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  animation: animeTop 0.3s forwards;
}

.card-carga {
  background-color: #ECECEC;
  border-radius: .4rem;
  margin: auto;
  padding: 1rem;
  opacity: 0;
  animation: animeLeft 0.3s forwards;
}

@keyframes animeTop {
  to {
    opacity: initial;
    transform: initial;
  }
}

/* @keyframes animeLeft {
  from {
    transform: translateX(-20px);
  }

  to {
    opacity: initial;
    transform: translateX(0px);
  }
} */

.relacionados:hover .hidden,
.tables {
  color: #fff;
  display: block;
  position: fixed;
  width: 30%;
  left: 70%;
  margin: .4rem 0 .4rem 0;
  border-radius: 5px 0 0 5px;
  background-color: #73B054;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  animation: animeLeft 0.3s forwards;
  z-index: 10000;
}

@keyframes animeLeft {
  to {
    opacity: initial;
    transform: initial;
  }
}


@media (max-width:992px) {
  .hr_show {
    display: none;
  }
}


@media (max-width:576px) {
  .hr_show {
    display: block;
  }
}

.pdf-view {
  display: flex;
  flex: 1 1 1;
  height: 780px;
  border-radius: .4rem;
}

.error {
  color: #FF0000;
}